// JsFromRoutes CacheKey 358da58dd58d4ec7288861914a23eceb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  loggedUser: definePathHelper('get', '/javascript/data/logged-user/:fields'),
  loggedOrganisation: definePathHelper('get', '/javascript/data/logged-user/organisation/:fields'),
  loggedParticipant: definePathHelper('get', '/javascript/data/logged-participant/:document_id/:fields'),
  template: definePathHelper('get', '/javascript/data/template/:id/:fields'),
  templateVersion: definePathHelper('get', '/javascript/data/template-version/:id/:fields'),
  document: definePathHelper('get', '/javascript/data/document/:id/:fields'),
  draft: definePathHelper('get', '/javascript/data/draft/:id/:fields'),
  deal: definePathHelper('get', '/javascript/data/deal/:id/:fields'),
  customClauseVariant: definePathHelper('get', '/javascript/data/custom-clause-variant/:id/:fields'),
  customClauses: definePathHelper('get', '/javascript/data/custom-clause/:id/:fields'),
  documentView: definePathHelper('get', '/javascript/data/views/document/:document_id'),
  templateView: definePathHelper('get', '/javascript/data/views/template/:template_id'),
}
