// JsFromRoutes CacheKey c9a7211c5f94624f335b6d4abf712f77
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  action: definePathHelper('post', '/deals/:deal_id/execution/action'),
  export: definePathHelper('post', '/deals/:deal_id/execution/export'),
  get: definePathHelper('get', '/deals/:deal_id/execution'),
  update: definePathHelper('patch', '/deals/:deal_id/execution'),
}
