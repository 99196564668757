// JsFromRoutes CacheKey e83c8e065493d2ca1f2635e674b4b33a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  public: definePathHelper('post', '/deals/:deal_id/dashboard/public'),
  private: definePathHelper('post', '/deals/:deal_id/dashboard/private'),
  export: definePathHelper('post', '/deals/:deal_id/dashboard/export'),
  generateXlsx: definePathHelper('post', '/deals/:deal_id/dashboard/generate_xlsx'),
  list: definePathHelper('get', '/deals/:deal_id/dashboard'),
  create: definePathHelper('post', '/deals/:deal_id/dashboard'),
  update: definePathHelper('patch', '/deals/:deal_id/dashboard/:id'),
}
