// JsFromRoutes CacheKey 7d74dbe17df006ed512f5fa0c1bb5f1b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  xlsx: definePathHelper('post', '/deals/:deal_id/import/xlsx'),
  docx: definePathHelper('post', '/deals/:deal_id/import/docx'),
  docx2: definePathHelper('post', '/deals/:deal_id/import/docx_2'),
  templates: definePathHelper('post', '/deals/:deal_id/import/templates'),
  data: definePathHelper('post', '/deals/:deal_id/import/data'),
  templatesForImport: definePathHelper('get', '/deals/:deal_id/import/templates_for_import'),
  dataForImport: definePathHelper('get', '/deals/:deal_id/import/data_for_import'),
}
