// JsFromRoutes CacheKey 21055ec313af48e692bbb6ca82cc1c72
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  document: definePathHelper('post', '/deals/:deal_id/preview/document'),
  exportEnvelopes: definePathHelper('post', '/deals/:deal_id/preview/export_envelopes'),
  exportDocuments: definePathHelper('post', '/deals/:deal_id/preview/export_documents'),
  get: definePathHelper('get', '/deals/:deal_id/preview'),
}
